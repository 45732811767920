module.exports = {
  title: `Deploy on Friday`,
  description: `Blog posted about ...`,
  author: `Minato Sato`,
  introduction: `Senior Software Engineer - Embedded AI`,
  siteUrl: `https://minatosato.com`, // Your blog site url
  social: {
    twitter: `minatosatou`, // Your Twitter account
    github: `minatosato`, // Your GitHub account
    medium: ``, // Your Medium account
    facebook: `mntsato`, // Your Facebook account
    linkedin: `minato-sato`, // Your LinkedIn account
  },
  icon: `content/assets/profile.png`, // Add your favicon
  keywords: [`blog`],
  comment: {
    disqusShortName: 'satopirka-com', // Your disqus-short-name. check disqus.com.
    utterances: '', // Your repository for archive comment
  },
  configs: {
    countOfInitialPost: 10, // Config your initial count of post
  },
  sponsor: {
    buyMeACoffeeId: 'satopirkacom',
  },
  share: {
    facebookAppId: '', // Add facebookAppId for using facebook share feature v3.2
  },
  ga: 'UA-50450234-4', // Add your google analytics tranking ID
}
